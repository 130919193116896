'use strict';

function heightNormalizer(elementsListToTreat, maxHeight) {
    var theMaxHeight = maxHeight;
    $(elementsListToTreat).each(function (idx, elem) {
        if ($(elem).height() > theMaxHeight) { theMaxHeight = $(this).height(); }
    });

    $(elementsListToTreat).css('min-height', theMaxHeight);
}

module.exports = function () {
    $(document).ready(function () {
        if ($(window).width() >= 800) {
            heightNormalizer('[data-js-normalize-height]', 0);
        }
    });
};
